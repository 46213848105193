var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              option: _vm.tableOption,
              data: _vm.tableData,
              page: _vm.page,
              defaults: _vm.defaults
            },
            on: {
              "update:defaults": function($event) {
                _vm.defaults = $event
              },
              "tree-load": _vm.treeLoad,
              "current-change": _vm.currentChange,
              "size-change": _vm.sizeChange,
              "selection-change": _vm.selectionChange,
              "refresh-change": _vm.refreshChange,
              "row-save": _vm.handleSave,
              "row-update": _vm.handleUpdate,
              "row-del": _vm.handleDel
            },
            scopedSlots: _vm._u([
              {
                key: "groupForm",
                fn: function(scope) {
                  return [
                    _c("el-autocomplete", {
                      staticClass: "inline-input",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "fetch-suggestions": _vm.querySearch,
                        placeholder:
                          _vm.$t("Please enter") + _vm.$t("UOM GROUPING"),
                        disabled: _vm.obj.deriveType > 1
                      },
                      model: {
                        value: _vm.obj.group,
                        callback: function($$v) {
                          _vm.$set(_vm.obj, "group", $$v)
                        },
                        expression: "obj.group"
                      }
                    })
                  ]
                }
              },
              {
                key: "groupError",
                fn: function(ref) {
                  var error = ref.error
                  return [
                    _c("p", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(error))
                    ])
                  ]
                }
              },
              {
                key: "menu",
                fn: function(scope) {
                  return [
                    scope.row.deriveType > 1
                      ? _c("el-button", {
                          attrs: {
                            icon: "el-icon-s-tools",
                            type: "text",
                            size: "medium",
                            title: _vm.$t("CUSTOM")
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.crud.rowEdit(
                                scope.row,
                                scope.row.index
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    scope.row.dataType == 3
                      ? _c("el-button", {
                          attrs: {
                            icon: "el-icon-remove",
                            type: "text",
                            size: "medium",
                            title: _vm.$t("HIDDEN SYSTEM ITEM")
                          },
                          on: {
                            click: function($event) {
                              return _vm.syshidden(scope.row)
                            }
                          }
                        })
                      : _vm._e(),
                    scope.row.deriveType == 4 || scope.row.deriveType == 5
                      ? _c("el-button", {
                          attrs: {
                            icon: "el-icon-refresh-left",
                            type: "text",
                            size: "medium",
                            title: _vm.$t("RESTORE SYSTEM ITEM")
                          },
                          on: {
                            click: function($event) {
                              return _vm.sysRestore(scope.row)
                            }
                          }
                        })
                      : _vm._e(),
                    scope.row.dataType == 0 &&
                    scope.row.deriveType != 4 && scope.row.deriveType != 5
                      ? _c("el-button", {
                          attrs: {
                            icon: "el-icon-edit-outline",
                            type: "text",
                            size: "medium",
                            title: _vm.$t("Edit")
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.crud.rowEdit(
                                scope.row,
                                scope.row.index
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    scope.row.dataType == 0 &&
                    scope.row.deriveType != 4 && scope.row.deriveType != 5
                      ? _c("el-button", {
                          attrs: {
                            icon: "el-icon-delete-solid",
                            type: "text",
                            size: "medium",
                            title: _vm.$t("Delete")
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleDel(scope.row)
                            }
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ]),
            model: {
              value: _vm.obj,
              callback: function($$v) {
                _vm.obj = $$v
              },
              expression: "obj"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }