<template>
    <div>
        <basic-container>
            <avue-crud :option="tableOption"
                       :data="tableData"
                       v-model="obj"
                       :page="page"
                       ref="crud"
                       :defaults.sync="defaults"
                       @tree-load="treeLoad"
                       @current-change="currentChange"
                       @size-change="sizeChange"
                       @selection-change="selectionChange"
                       @refresh-change="refreshChange"
                       @row-save="handleSave"
                       @row-update="handleUpdate"
                       @row-del="handleDel">

                <template slot="groupForm" scope="scope">
                    <el-autocomplete class="inline-input"
                                     v-model="obj.group"
                                     :fetch-suggestions="querySearch"
                                     :placeholder="$t('Please enter')+$t('UOM GROUPING')"
                                     v-bind:disabled="obj.deriveType>1"
                                     style="width:100%;"></el-autocomplete>
                </template>
                <template slot-scope="{error}" slot="groupError">
                    <p style="color:red">{{error}}</p>
                </template>
                <!--<template slot="status" slot-scope="scope">
        <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                   @change="change(scope.row)">
        </el-switch>
    </template>-->
                <!--<template slot="menuRight">
        <el-select clearable v-model="status" :placeholder="$t('STATE')" size="small" style="width:100px !important;marginRight: 5px;">
            <el-option :label="$t('NORMAL')" value="0">{{$t('NORMAL')}}</el-option>
            <el-option :label="$t('DISABLE')" value="1">{{$t('DISABLE')}}</el-option>
        </el-select>
        <el-input v-model="nameOrCod" :placeholder="$t('UOM OR CODE')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
    </template>-->

                <template slot="menu" slot-scope="scope">
                    <el-button icon="el-icon-s-tools" type="text" size="medium" :title="$t('CUSTOM')" v-if="scope.row.deriveType>1" @click="$refs.crud.rowEdit(scope.row,scope.row.index)"></el-button>
                    <el-button icon="el-icon-remove" type="text" size="medium" :title="$t('HIDDEN SYSTEM ITEM')" v-if="scope.row.dataType==3" @click="syshidden(scope.row)"> </el-button>
                    <el-button icon="el-icon-refresh-left" type="text" size="medium" :title="$t('RESTORE SYSTEM ITEM')" v-if="scope.row.deriveType==4||scope.row.deriveType==5" @click="sysRestore(scope.row)"> </el-button>

                    <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" v-if="scope.row.dataType==0&&(scope.row.deriveType!=4&&scope.row.deriveType!=5)" @click="$refs.crud.rowEdit(scope.row,scope.row.index)"> </el-button>
                    <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')" v-if="scope.row.dataType==0&&(scope.row.deriveType!=4&&scope.row.deriveType!=5)" @click="handleDel(scope.row)"> </el-button>
                </template>
            </avue-crud>
        </basic-container>
    </div>
</template>

<script>
    import { add, edit, remove, audit, AllGroup, GroupDown, CodeIsExist, StandardIsExist, ShowGroupName } from "@/api/math"
export default {
        data() {
            var validateCodeExist = (rule, value, callback) => {
                if ((value != '' || value != null)) {
                    var ids = this.obj.id;
                    if (this.obj.id == undefined) {
                        ids = "";
                    }
                    CodeIsExist(ids, value).then(resu => {
                        var IsExist = resu.data.data;

                        if (!IsExist) {
                            callback(new Error(this.$t('CodeIsExist')));
                        } else { callback(); }
                    }).catch(() => { callback(); });
                } else {
                    callback();
                }
            };
            var validateGroups = (rule, value, callback) => {
                var ids = this.obj.id;
                var isBases = this.obj.isBase;
                console.log(value);
                console.log(isBases);
                if (this.obj.id == undefined) {
                    ids = "";
                }
                if (value != "") {
                    StandardIsExist(value, ids).then(resu => {
                        console.log(323);
                        console.log(resu);
                        var IsExist = resu.data.data;
                        if (!IsExist && isBases == 1) {
                            callback(new Error(this.$t('UOM StandardIsExist')));
                        } else if (IsExist && isBases == 0) { callback(new Error(this.$t('UOM StandardNotExist'))); }
                        else { callback(); }
                    }).catch(() => { callback(); });
                }
                else if (value == "" && isBases == 0) {
                    callback(new Error(this.$t('Please fill in the company group first')));
                } else { callback(); }

            };
            var validatescaleFactor = (rule, value, callback) => {
                var ids = this.obj.id;
                var groups = this.obj.group;
                if (this.obj.id == undefined) {
                    ids = "";
                }
                if (groups != "" && (value == 0 || value == undefined)) {
                    callback(new Error(this.$t('Conversion factor under unit group is required')));
                }
                else {
                    callback();
                }

            };
            return {
                defaults: {},
                is_display: false,
                groupList: [],
                obj: {
                    deriveType: 1,
                },
                ListDown: [],
                selectionList: [],
                nameOrCod: "",
                status: "0",
                page: {
                    pageSize: 20,
                    pagerCount: 5,
                    total: 10,
                    pageCurrent: 1
                },
                tableData: [],
                tableOption: {
                    rowKey: 'id',
                    lazy: true,
                    editBtn: false,
                    delBtn: false,
                    menuWidth: 120,
                    labelWidth: 180,
                    selection: true,
                    dialogFullscreen: true,
                    tip: false,
                    columnBtn: false,
                    addBtnText: this.$t('ADD'),
                    delBtnText: this.$t('DELETE'),
                    editBtnText: this.$t('Edit'),
                    emptyText: this.$t('No Data'),
                    menuTitle: this.$t('OPERATION'),
                    column: [

                        {
                            label: "是否有下级",
                            prop: "hasChildren",
                            hide: true,
                            addDisplay: false,
                            editDisplay: false
                        },
                        {
                            label: "数据类型",
                            prop: "dataType",
                            hide: true,
                            addDisplay: false,
                            editDisplay: false
                        },
                        {
                            label: this.$t('REFERENCE UOM'),
                            prop: "isStandard",
                            hide: true,
                            addDisplay: false,
                            editDisplay: false,
                        },
                        {
                            label: this.$t('NAME'),
                            prop: "name",
                            placeholder: this.$t('PLEASE ENTER A NAME'),
                            rules: [{ required: true, message: this.$t('PLEASE ENTER A NAME'), trigger: "blur" }]
                        },
                        {
                            label: this.$t('CODE'),
                            prop: "code",
                            placeholder: this.$t('PLEASE ENTER THE CODE'),
                            rules: [{ required: true, message: this.$t('PLEASE ENTER THE CODE'), trigger: "blur" },
                            { validator: validateCodeExist, trigger: "blur" }]
                        }, {
                            label: this.$t('REFERENCE UOM'),
                            prop: "isBase",
                            type: "radio",
                            dicData: [{
                                label: this.$t('True'),
                                value: 1
                            }, {
                                label: this.$t('False'),
                                value: 0
                            }],
                            rules: [{
                                required: true, message: this.$t('Pleaseselect'), trigger: "change"
                            },
                            ],
                            hide: true,
                        },
                        {
                            label: this.$t('UOM GROUPING'),
                            prop: "group",
                            formslot: true,
                            errorslot: true,
                            placeholder: this.$t('PLEASE ENTER UOM GROUP'),
                            rules: [{ validator: validateGroups, trigger: "change" }]
                        },
                        {
                            label: this.$t('CONVERSION FACTOR'),
                            value: 0,
                            prop: "scaleFactor",
                            type: "number",
                            rules: [{ validator: validatescaleFactor, trigger: "blur" }],
                            width: 100,
                        },
                        {
                            label: this.$t('DECIMAL PLACES'),
                            value: 0,
                            type: "number",
                            prop: "preci",
                            rules: [{ required: true, message: this.$t('PLEASE ENTER THE NUMBER OF DECIMAL PLACES'), trigger: "blur" }]
                        },
                        {
                            label: this.$t('SORT'),
                            value: 0,
                            type: "number",
                            sortable: true,
                            prop: "sort"
                        },
                        {
                            label: this.$t('DATA STATUS'),
                            type: "select",
                            addDisplay: false,
                            prop: "status",
                            dicData: [
                                {
                                    label: this.$t('DISABLE'),
                                    value: 1
                                }, {
                                    label: this.$t('NORMAL'),
                                    value: 0
                                }
                            ]
                        },
                        {
                            label: this.$t('DATATYPE'),
                            prop: "deriveType",
                            type: "select",
                            dicData: [
                                {
                                    label: this.$t('System item - not adjustable'),
                                    value: 1
                                },
                                {
                                    label: this.$t('System item - adjustable, not hidden'),
                                    value: 2
                                },
                                {
                                    label: this.$t('System item - adjustable and hidden'),
                                    value: 3
                                },
                                {
                                    label: this.$t('System item - adjustable, not hidden'),
                                    value: 4
                                },
                                {
                                    label: this.$t('System item - adjustable and hidden'),
                                    value: 5
                                },
                                {
                                    label: this.$t('Non system item'),
                                    value: 0
                                }
                            ],
                            addDisplay: false,
                            editDisplay: false,
                        },

                    ]
                }
            }
        },
        watch: {
            //'obj.isBase'(val) {
            //    if (val == 1) {
            //        console.log(434);
            //        console.log(this.defaults);
            //        this.obj.scaleFactor = 1;
            //        this.defaults.scaleFactor.disabled = true;

            //    } else {
            //        this.defaults.scaleFactor.disabled = false;
            //    }
            //},
            'obj.deriveType'(val) {
                if (val > 1) {
                    this.defaults.code.disabled = true;
                    this.defaults.isBase.disabled = true;
                    if (val == 5) {
                        this.defaults.status.disabled = false;
                    } else {
                        this.defaults.status.disabled = true;
                    }

                } else {
                    this.defaults.code.disabled = false;
                    this.defaults.isBase.disabled = false;
                    this.defaults.status.disabled = false;
                }
            },
     

        },
        created() {
            ShowGroupName().then(res => {
                this.groupList = res.data.data;
            });
            this.unitlist();
        },
        methods: {
            currentChange(pageCurrent) {
                this.page.pageCurrent = pageCurrent;
                this.unitlist();
            },
            sizeChange(pageSize) {
                this.page.pageCurrent = 1;
                this.page.pageSize = pageSize;
                this.unitlist();
            },
            refreshData() {
                this.unitlist();
            },
            returnAudit() {
                audit(this.selectionList[0].id, 0).then(() => {
                    this.$message({
                        type: "success",
                        message: this.$t('DE APPROVAL SUCCEEDED')
                    });
                    this.unitlist();
                })
            },
            audit() {
                audit(this.selectionList[0].id, 1).then(() => {
                    this.$message({
                        type: "success",
                        message: this.$t('AUDIT SUCCEEDED')
                    });
                    this.unitlist();
                });
            },
            handleDel(row) {
                this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: 'warning'
                }).then(() => {
                    remove(row.id).then(() => {
                        this.unitlist();
                    })
                })
            },
            syshidden(row) {
                this.$confirm(this.$t('Is') + this.$t('HIDDEN SYSTEM ITEM'), this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: 'warning'
                }).then(() => {
                    row.status = 1;
                    row.dataType = 0;
                    row.id = '';
                    add(row).then(() => {
                        this.$message({
                            type: "success",
                            message: this.$t('OperationSuccess')
                        });
                        this.unitlist();
                    })
                })
            },
            sysRestore(row) {
                this.$confirm(this.$t('Is') + this.$t('RESTORE SYSTEM ITEM'), this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: 'warning'
                }).then(() => {
                    remove(row.id).then(() => {
                        this.unitlist();
                    })
                })
            },
            handleUpdate(row, index, done) {
                console.log(row);
                if (row.deriveType == 2 || row.deriveType == 3) {
                    row.status = 0;
                    row.dataType = 0;
                    row.id = '';
                    add(row).then(() => {
                        this.$message({
                            type: "success",
                            message: this.$t('OperationSuccess')
                        });
                        this.unitlist();
                    })
                }
                else {
                    if (row.deriveType == 4 || row.deriveType == 5) {
                        row.dataType = 0;
                    }
                    edit(row).then(() => {
                        this.$message({
                            type: "success",
                            message: this.$t('EDIT SUCCEEDED')
                        });
                        this.unitlist();
                        done();
                    })
                }
            },
            handleSave(row, done) {
                row.isStandard = 0;
                if(row.isBase==1){
                    if(row.group=="")
                    {
                      row.group = " "; 
                    }
                    if(row.scaleFactor==undefined)
                    {
                      row.scaleFactor =0; 
                    }
                }
                add(row).then(res => {
                    this.unitlist();
                    console.log(res);
                    res.data.code == "0000" ? this.$message({
                        type: "success",
                        message: this.$t('AddSuccess')
                    }) : "";
                    done();
                })
            },
            change(row) {
                //changeStatus(row)
            },
            selectionChange(list) {
                this.selectionList = list;
                list != "" ? this.disabled = false : this.disabled = true;
            },
            refreshChange() {
                this.unitlist();
            },
            unitlist() {
                this.tableData = [];
                this.ListDown = [];
                AllGroup(this.page.pageCurrent, this.page.pageSize).then(res => {
                    var listinfo = res.data.data;
                    this.page.total = listinfo.total;
                    this.tableData = listinfo.data;
                })
            },
            envText: function () {
                return this.env
            },
            treeLoad(tree, treeNode, resolve) {
                setTimeout(() => {
                    GroupDown(tree.group).then(res => {
                        this.ListDown = res.data.data;
                        resolve(this.ListDown)
                    })
                }, 500)
            },
            querySearch(query, cb) {
                let list = query ? this.groupList.filter(v => {
                    if (v) {
                        return v.indexOf(query) != -1;
                    }
                }) : this.groupList;
                let randerList = [];
                list ? list.forEach(v => {
                    randerList.push({ value: v });
                }) : "";
                if (!query) {
                    randerList = [];
                    this.groupList.forEach(v => {
                        randerList.push({ value: v });
                    });
                }
                cb(randerList);
            },
        }
    }
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>